<template>
  <b-card
    no-body
  >
    <b-modal
      id="access-point-modal"
      ref="modal"
      title="Edit Access Point"
      hide-footer
      size="lg"
      @hide="closeModal"
      @show="openModal"
    >
      <b-form>
        <validation-observer ref="accessPointModal">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Interface Name:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Interface Name:"
                    vid="name"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Interface Name:"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Comment:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Comment:"
                    vid="comment"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="form.comment"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Comment:"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="SSID:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="SSID:"
                    vid="ssid"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.ssid"
                      :state="errors.length > 0 ? false:null"
                      placeholder="SSID:"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Channel Selection:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Channel Selection:"
                    vid="channel"
                    rules="required"
                  >
                    <v-select
                      v-model="form.channel"
                      :options="channelList"
                      :reduce="channel => channel.id"
                      :searchable="false"
                      label="name"
                      transition=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Disable:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Disable:"
                    vid="disabled"
                  >
                    <b-form-checkbox
                      v-model="form.disabled"
                      name="disabled"
                      switch
                      inline
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-button-request
                  :disabled="disabledSaveButton"
                  text="Submit"
                  :request="editItem"
                  class-container="d-flex justify-content-end"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>

import { mapActions } from 'vuex'

import {
  BCard, BRow, BCol, BModal, BFormInput, BFormGroup, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { required, max } from '@validations'
import vSelect from 'vue-select'
import BButtonRequest from '../../../../../components/BButtonRequest.vue'

export default {
  components: {
    BButtonRequest,
    BRow,
    BCol,
    BCard,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
  },
  props: {
    siteId: {
      required: true,
      type: String,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    isNewCapsMan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      max,
      form: {
        id: '',
        name: '',
        comment: '',
        ssid: '',
        channel: '',
        disabled: false,
      },
      channelList: [],
      disabledSaveButton: false,
    }
  },
  methods: {
    ...mapActions('accessPoints', [
      'updateItem',
    ]),
    openModal() {
      if (this.selectedItem) {
        this.channelList = this.selectedItem.channelList
      }
      this.form.id = this.selectedItem.id
      this.form.name = this.selectedItem.name
      this.form.comment = this.selectedItem.comment
      this.form.ssid = this.selectedItem.ssid
      this.form.channel = this.selectedItem.channel
      this.form.disabled = this.selectedItem.disabled
    },
    async editItem() {
      const valid = await this.$refs.accessPointModal.validate()
      if (valid) {
        try {
          this.disabledSaveButton = true
          await this.updateItem({ siteId: this.siteId, isNewCapsMan: this.isNewCapsMan, payload: this.form })
          this.$refs.modal.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('access-point-modal')
          this.disabledSaveButton = false
          this.closeModal()
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.accessPointModal.setErrors(fields)
          } else {
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })
          }
        } finally {
          this.disabledSaveButton = false
        }
      }
    },
    closeModal() {
      this.form = {
        name: '',
        comment: '',
        ssid: '',
        channel: '',
        disabled: false,
      }
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
