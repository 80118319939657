<template>
  <b-card
    no-body
  >
    <b-card-title class="m-2">
      Access Control
    </b-card-title>
    <b-row class="px-2">
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          v-b-modal.access-control-modal
          variant="primary"
        >
          Add Host
        </b-button>
      </b-col>
    </b-row>
    <CreateEditModal
      :site-id="siteId"
      :selected-item="selectedItem"
      :is-edit-form="isItemSelected"
      @close-modal="resetModal"
    />
    <b-card-body>
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        compact-mode
        :columns="fields"
        :rows="items"
        mode="remote"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'management'">
            <span class="d-flex justify-content-center">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.access-control-modal
                  @click.prevent="openEditModal(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="deleteAccessControl(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'signal_range_start'">
            <span>
              {{ props.formattedRow['signal_range_start'] }} .. {{ props.formattedRow['signal_range_end'] }}
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'time_start'"
            class="d-flex"
          >
            <span class="d-block border-right pr-1">
              {{ props.formattedRow['time_start'] }} - {{ props.formattedRow['time_end'] }}
            </span>
            <span class="d-block pl-1">
              <span
                v-for="(item, index) in props.formattedRow['days']"
                :key="index"
                :class="item.enable ? 'text-success' : 'text-danger'"
              >
                {{ item.title }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'action'"
          >
            <b-badge
              pill
              :variant="setStatusColor(props.formattedRow[props.column.field])"
            >
              <span class="text-white">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </b-badge>
          </span>
          <span
            v-else-if="props.column.field === 'disabled'"
          >
            <b-form-checkbox
              v-model="props.formattedRow[props.column.field]"
              name="disabled"
              value="false"
              switch
              inline
              @change="changeStatus(props.formattedRow)"
            />
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'

import {
  BCard, BCardTitle, BCardBody, BSpinner, BDropdown, BDropdownItem, BBadge, BButton, BCol, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import CreateEditModal from './CreateEditModal.vue'

export default {
  components: {
    BCardBody,
    VueGoodTable,
    BCard,
    BCardTitle,
    BSpinner,
    BDropdown,
    BButton,
    BCol,
    BRow,
    BDropdownItem,
    BFormCheckbox,
    CreateEditModal,
    BBadge,
  },
  mixins: [
    IndexPagesMixin,
  ],
  props: {
    siteId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      showModal: false,
      isItemSelected: false,
      selectedItem: null,
      fields: [
        {
          field: 'id',
          sortable: false,
          hidden: true,
        },
        {
          field: 'mac_address',
          label: 'MAC Address',
          sortable: false,
        },
        {
          field: 'host_name',
          label: 'Host Name',
          sortable: false,
        },
        {
          field: 'name',
          label: 'Name',
          sortable: false,
        },
        {
          field: 'signal_range_start',
          label: 'Signal Range',
          sortable: false,
        },
        {
          field: 'signal_range_end',
          label: 'Signal Range',
          sortable: false,
          hidden: true,
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
        },
        {
          field: 'time_start',
          label: 'TimeBlock Settings',
          sortable: false,
        },
        {
          field: 'time_end',
          label: 'TimeBlock Settings',
          sortable: false,
          hidden: true,
        },
        {
          field: 'days',
          label: 'Days',
          sortable: false,
          hidden: true,
        },
        {
          field: 'disabled',
          label: 'Type',
          sortable: false,
        },
        {
          field: 'management',
          label: 'Management',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
    }
  },
  computed: {
    ...mapState('accessControl', {
      items: 'items',
      searchFields: 'searchFields',
    }),
  },
  created() {
    this.fetchItems()
  },
  methods: {
    ...mapActions('accessControl', {
      getItems: 'getItems',
      deleteRecord: 'deleteItem',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      updateAccessControlStatusEnable: 'updateAccessControlStatusEnable',
      updateAccessControlStatusDisable: 'updateAccessControlStatusDisable',
    }),
    openEditModal(itemToEdit) {
      this.isItemSelected = true
      this.selectedItem = itemToEdit
    },
    resetModal() {
      this.isItemSelected = false
      this.selectedItem = null
    },
    async changeStatus(item) {
      try {
        if (item.disabled) {
          await this.updateAccessControlStatusEnable({ siteId: this.siteId, ids: [item.id] })
        } else {
          await this.updateAccessControlStatusDisable({ siteId: this.siteId, ids: [item.id] })
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { message } = error.response.data

        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'BellIcon',
            variant: 'danger',
            message,
          },
        })
      }
    },
    async fetchItems() {
      try {
        await this.getItems(this.siteId)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    async deleteAccessControl(id) {
      try {
        await this.deleteRecord({ siteId: this.siteId, ids: [id] })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      }
    },
    setStatusColor(statusName) {
      let colorVariant
      switch (statusName) {
        case 'accept':
          colorVariant = 'success'
          break
        default:
          colorVariant = 'danger'
      }
      return colorVariant
    },
  },
}
</script>

<style lang="scss">
</style>
