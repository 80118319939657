<template>
  <b-card
    no-body
    class="no-padding"
  >
    <div class="section-block m-2">
      <b-card-title>
        <div class="site-hardware-actions site-hardware-actions_management">
          Active Leases
          <div class="double-button">
            <b-button
              variant="outline-primary"
              @click="updateItems"
            >
              <feather-icon
                icon="RefreshCwIcon"
                width="16"
                height="16"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="navigateToAddDHCPEmpty"
            >
              <feather-icon
                icon="PlusIcon"
                width="16"
                height="16"
                class="mr-25"
              />
              Add Reservation
            </b-button>
          </div>
        </div>
      </b-card-title>
      <b-card-body>
        <vue-good-table
          ref="VueGoodTable"
          :is-loading="isLoading"
          :columns="fields"
          :rows="items"
          mode="remote"
          compact-mode
        >
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'static_address'"
              class="d-flex justify-content-center"
            >
              <b-button
                variant="outline-primary"
                class="table-button"
                :class="{ 'table-button-not-active': props.row.dynamic == 'true' }"
                @click="handleSwitchStatic(props.row.dynamic == 'false', props.row['.id'])"
              >
                <feather-icon
                  v-if="props.row.dynamic == 'false'"
                  icon="LockIcon"
                  size="17"
                  class="text-body align-middle"
                  stroke="#F0554E"
                />
              </b-button>
            </span>
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="props.row.dynamic == 'true'"
                    @click.prevent="navigateToAddDHCP(props.row['.id'])"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Make static</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.dynamic == 'false'"
                    @click.prevent="navigateToUpdateDHCP(props.row['.id'])"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click.prevent="deleteRow(props.row['.id'])"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Remove</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'

import {
  BCard, BCardTitle, BCardBody, BDropdown, BDropdownItem, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { required, max } from '@validations'

import { VueGoodTable } from 'vue-good-table'
import axios from '../../axios-resolver'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardBody,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BButton,
    BSpinner,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
    siteId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      fields: [
        { field: 'address', label: 'Address' },
        {
          field: 'mac_address',
          label: 'MAC Address',
          sortable: false,
        },
        {
          field: 'server',
          label: 'DHCP Server',
          sortable: false,
        },
        { field: 'host_name', label: 'Host Name' },
        { field: 'dns_server_2', label: 'Expiry' },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          field: 'ntp_server_2',
          label: 'Static Res.',
          sortable: false,
        },
        {
          field: 'address_lists',
          label: 'Address List',
          sortable: false,
        },
        { field: 'comment', label: 'Comment' },
        { field: 'host_name', label: 'Domain name' },
        {
          field: 'static_address',
          label: 'Static address',
          sortable: false,
        },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      itemToEdit: null,
      required,
      max,
    }
  },
  computed: {
    ...mapState('dhcp', {
      items: 'items',
    }),
  },
  created() {
    this.fetchItems()
  },
  methods: {
    ...mapActions('dhcp', [
      'getLeases',
      'updateItem',
      'deleteItem',
    ]),
    navigateToAddDHCP(id = null) {
      this.$router.push({ name: 'site-summary-add-active-leases', params: { leaseId: id } })
    },
    navigateToAddDHCPEmpty() {
      this.$router.push({
        name: 'site-summary-add-active-leases-empty',
        params: {
          id: this.siteId,
        },
      })
    },
    navigateToUpdateDHCP(id) {
      this.$router.push({ name: 'site-summary-update-active-leases', params: { leaseId: id, id: this.siteId } })
    },
    async fetchItems() {
      try {
        await this.getLeases(this.siteId)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    async makeStatic(id) {
      try {
        await axios.put(`/site/${this.siteId}/network/active-lease/make-static`, {
          ids: [id],
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        await this.fetchItems()
      } catch (error) {
        console.warn(error)
      }
    },
    async handleSwitchStatic(isStatic, id) {
      if (isStatic) {
        await this.deleteRow(id)
      } else {
        await this.makeStatic(id)
      }
    },
    async updateItems() {
      this.isLoading = true
      await this.fetchItems()
    },
    async deleteRow(id) {
      try {
        await axios.delete(`/site/${this.siteId}/network/active-lease/${id}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        await this.fetchItems()
      } catch (error) {
        console.warn(error)
      }
    },
    closeModal() {
      this.itemToEdit = null
    },
  },
}
</script>

<style lang="scss">

.no-padding {
  .card-body{
    padding: 0;
  }
}
</style>
