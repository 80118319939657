<template>
  <b-card
    no-body
    class="no-padding"
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      title="Are you sure you want to permanently delete this record?"
      centered
      ok-title="Delete"
      @ok="deleteRow"
    >
      <div>
        Permanently deleted records cannot be recovered later.
      </div>
    </b-modal>
    <div class="section-block m-2">
      <div class="site-hardware-actions site-hardware-actions_management mb-2">

        <div class="section-block__title mb-0">
          <h2>DHCP Servers</h2>
        </div>
        <div class="double-button">
          <b-dropdown
            toggle-class="more-dropdown-transparent"
            dropdown
            right
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle"
                color="#F0554E"
              />
            </template>
            <b-dropdown-item
              @click="pushToSite"
            >
              <feather-icon
                icon="UploadCloudIcon"
                size="20"
                class="mr-50"
              />
              <span>Push to</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="pullFromSite"
            >
              <feather-icon
                icon="DownloadCloudIcon"
                size="20"
                class="mr-50"
              />
              <span>Pull from</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="navigateToAddDHCP"
          >
            <feather-icon
              icon="PlusIcon"
              width="16"
              height="16"
              class="mr-25"
            />
            Add DHCP Server
          </b-button>
        </div>
      </div>
      <b-card-body>
        <vue-good-table
          ref="VueGoodTable"
          :is-loading="isLoading"
          :columns="fields"
          :rows="items"
          mode="remote"
          compact-mode
          :total-rows="pagination.total"

          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: {field: orderBy, type: sortedBy}
          }"
          :pagination-options="{
            enabled: true,
            perPage: listParams.perPage,
            setCurrentPage: listParams.page
          }"
          @on-sort-change="onSortListChange"
          @on-column-filter="onColumnFilter"
        >
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'enable_server'">
              <b-form-checkbox
                v-model="props.row.is_active"
                name="is_enable"
                switch
                inline
                aria-label="fff"
                @change="updateDHCPStatus(props.row.id, props.row.is_active)"
              />
            </span>
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.modal-edit
                    @click.prevent="navigateToUpdateDHCP(props.row.id)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="props.row.is_default"
                    @click.prevent="deleteHandler(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="listParams.perPage"
                  :options="pageSizeOptions"
                  class="mx-1"
                  @change="perPageChanged"
                />
                <span class="text-nowrap"> of {{ pagination.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-if="pagination.last_page !== 1"
                  :value="listParams.page"
                  :total-rows="pagination.total"
                  :per-page="listParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="changePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </div>
    <b-modal
      id="modalDeleteLoader"
      ref="modalDeleteLoader"
      :title="`${modalTitle}...`"
      hide-footer
      centered
      hide-close
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      size="sm"
    >
      <div class="d-flex justify-content-center align-items-center mt-2 mb-2">

        <b-spinner
          variant="primary"
          label="Spinning"
          style="width: 3rem; height: 3rem;"
          huge
        />
      </div>
    </b-modal>
  </b-card>
</template>

<script>

import {
  BCard, BCardBody, BDropdown, BDropdownItem, BButton, BSpinner,
  BPagination,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'
import Ripple from 'vue-ripple-directive'

import { VueGoodTable } from 'vue-good-table'
import axios from '../../axios-resolver'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardBody,
    VueGoodTable,
    BFormCheckbox,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      search: '',
      searchFields: '',
      orderBy: '',
      sortedBy: '',
      pagination: {},
      listParams: {
        page: 1,
        perPage: 25,
        searchJoin: 'and',
      },
      modalTitle: '',
      recordToDelete: null,
      pageSizeOptions: [25, 50, 75, 100],
      fields: [
        {
          field: 'name',
          label: 'DHCP Server',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'gateway_ip',
          label: 'Gateway IP',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'subnet_mask',
          label: 'Subnet Mask',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'dns1_ip',
          label: 'DNS Server',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'dns2_ip',
          label: 'DNS Server #2',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'ntp1_ip',
          label: 'NTP Server',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'ntp2_ip',
          label: 'NTP Server #2',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'wifi_controller_ip',
          label: 'WiFi Controller',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'ip_pool_start',
          label: 'IP Pool Start',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'ip_pool_end',
          label: 'IP Pool End',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'lease_time',
          label: 'Lease Time',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'domain_name',
          label: 'Domain Name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'enable_server',
          label: 'Enable Server',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      itemToEdit: null,
      required,
      max,
    }
  },
  watch: {
    recordToDelete(val) {
      if (val) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
  },
  methods: {
    async pullFromSite() {
      try {
        this.modalTitle = 'Pulling'
        this.$refs.modalDeleteLoader.show()
        await axios.put(`/site/${this.$route.params.id}/network/dhcp-server/pull`)
        this.getLeases(true)
      } catch (error) {
        console.warn(error)
      } finally {
        this.$refs.modalDeleteLoader.hide()
      }
    },
    async pushToSite() {
      try {
        this.modalTitle = 'Pushing'
        this.$refs.modalDeleteLoader.show()
        await axios.put(`/site/${this.$route.params.id}/network/dhcp-server/push`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data pushed to the controller',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.getLeases()
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.$refs.modalDeleteLoader.hide()
      }
    },
    navigateToAddDHCP() {
      this.$router.push({ name: 'site-summary-add-dhcp' })
    },
    navigateToUpdateDHCP(dhcpId) {
      this.$router.push({ name: 'site-summary-update-dhcp', params: { id: +this.$route.params.id, dhcpId } })
    },
    async getLeases(isPulled = false) {
      const sortOptionURL = this.orderBy && this.sortedBy ? `&orderBy=${this.orderBy}&sortedBy=${this.sortedBy}` : ''
      const searchURL = this.search ? `&search=${this.search}&searchFields=${this.searchFields}` : ''
      const { data } = await axios.get(`/site/${this.$route.params.id}/network/dhcp-server/index?page=${this.listParams.page}&perPage=${this.listParams.perPage}&searchJoin=${this.listParams.searchJoin}${sortOptionURL}${searchURL}`)
      this.items = data.data
      this.isLoading = false
      this.pagination = data.meta.pagination

      if (isPulled) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data pulled from the controller',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
    },
    onSortListChange(params) {
      this.orderBy = params[0].field
      this.sortedBy = params[0].type
      this.getLeases()
    },
    onColumnFilter(prop) {
      const filterParams = prop.columnFilters

      let search = ''
      let searchFields = ''

      Object.keys(filterParams).forEach(key => {
        if (filterParams[key]) {
          search += `${key}:${filterParams[key]};`
          // remake, only the created_at, activation_at, renewal_at, expiry_at should have :like
          if (key === 'created_at' || key === 'activation_at' || key === 'renewal_at' || key === 'expiry_at') {
            searchFields += `${key}:like;`
          } else {
            searchFields += `${key};`
          }
        }
      })
      this.search = search
      this.searchFields = searchFields

      this.getLeases()
    },
    perPageChanged(val) {
      this.listParams.perPage = val
      this.getLeases()
    },
    changePage(val) {
      this.listParams.page = val
      this.getLeases()
    },
    async updateDHCPStatus(dhcpId, isEnable) {
      const switchTo = isEnable ? 'enable' : 'disable'
      try {
        await axios.put(`/site/${this.$route.params.id}/network/dhcp-server/${dhcpId}/${switchTo}`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: isEnable ? 'DHCP Server Enabled' : 'DHCP Server Disabled',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      }
    },
    async editItem() {
      const valid = await this.$refs.dhcpLeasesUpdateModal.validate()

      if (valid) {
        try {
          await this.updateItem({ id: this.hardwareId, payload: this.itemToEdit, isStatic: false })
          this.$refs.modal.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
            this.$refs.dhcpLeasesUpdateModal.setErrors(fields)
          } else {
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })
          }
        }
      }
    },
    async deleteHandler(id) {
      this.recordToDelete = id
    },
    async deleteRow() {
      try {
        await axios.delete(`/site/${this.$route.params.id}/network/dhcp-server/${this.recordToDelete}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.getLeases()
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      }
    },
    closeModal() {
      this.itemToEdit = null
    },
  },
}
</script>

<style lang="scss">
</style>
