var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"licenceCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"pl-4 pr-4",attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("WAN Network Settings")])],1),_c('b-form-group',{attrs:{"label":"Type","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Type","vid":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"no-icon",attrs:{"options":_vm.types,"placeholder":"Select type","label":"type","clearable":false,"searchable":false,"dropdown-should-open":function () { return true; }},on:{"input":_vm.checkFields},model:{value:(_vm.typeActive),callback:function ($$v) {_vm.typeActive=$$v},expression:"typeActive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.typeActive.includes('PPPoE'))?_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Username","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Username","autocomplete":"off"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3302440212)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Password","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Password","autocomplete":"off","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3504352517)})],1)],1):_vm._e(),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Interface","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"interface_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Interface","autocomplete":"off","onchange":function (value) { return _vm.form.interface_ip = value; },"disabled":_vm.typeActive.includes('PPPoE')|| _vm.typeActive.includes('DHCP Client'),"model":_vm.form.interface_ip,"inputs-id":"interface_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",class:{ 'invisible': _vm.typeActive.includes('PPPoE') },attrs:{"label":"Subnet Mask","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"subnet_mask","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Subnet Mask","autocomplete":"off","onchange":function (value) { return _vm.form.subnet_mask = value; },"disabled":!_vm.typeActive.includes('Static'),"model":_vm.form.subnet_mask,"inputs-id":"subnet_mask"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.typeActive.includes('PPPoE'))?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Gateways Address","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"gateway_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Gateways Address","autocomplete":"off","onchange":function (value) { return _vm.form.gateway_ip = value; },"disabled":_vm.typeActive.includes('DHCP Client'),"model":_vm.form.gateway_ip,"inputs-id":"gateway_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3666544491)})],1):_vm._e(),(_vm.typeActive.includes('PPPoE'))?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Network Address","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"network_ip","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Network Address","autocomplete":"off","onchange":function (value) { return _vm.form.network_ip = value; },"disabled":true,"model":_vm.form.network_ip,"inputs-id":"network_ip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2341902044)})],1):_vm._e()],1),_c('div',{staticClass:"double-button"},[_c('b-button-request',{attrs:{"type":"reset","text":"Reset","variant":_vm.outline-_vm.secondary,"request":_vm.resetForm}}),_c('b-button-request',{attrs:{"text":"Save","request":_vm.validationForm,"disabled":_vm.typeActive.includes('PPPoE') && (!_vm.form.interface_ip || !_vm.form.network_ip)}})],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }