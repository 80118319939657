<template>
  <div>
    <canvas
      :id="id"
      width="400"
      height="100"
    />
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'Chart',
  props: {
    id: {
      required: true,
      type: String,
    },
    traffic: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    traffic() {
      this.updateChartData()
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      const labels = []
      for (let count = 0; count <= 60; count += 2) {
        labels.push(count)
      }
      const config = {
        type: 'bar',
        data: {
          labels: [...labels],
          datasets: [
            {
              label: 'Download',
              backgroundColor: 'green',
              borderColor: 'green',
              data: [],
            },
            {
              label: 'Upload',
              backgroundColor: 'orange',
              borderColor: 'orange',
              data: [],
            },
          ],
        },
        options: {
          scales: {
            xAxes: [{
              type: 'realtime',
            }],
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              position: 'top',
              text: 'Traffic',
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label(context) {
                  const label = context.dataset.label || ''
                  return label.concat(': ', new Intl.NumberFormat('en-US', { style: 'decimal' }).format(context.parsed.y), ' kbits')
                  //     let label = context.dataset.label || ''

                  //     if (label) {
                  //       label += ': '
                  //     }
                  //     if (context.parsed.y !== null) {
                  //       label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y)
                  //     }
                  //     return label
                },
              },
            },
          },
        },
      }
      this.chart = new Chart(
        window.document.getElementById(this.id),
        config,
      )
      this.updateChartData()
      // setInterval(() => {
      //  this.chart.data.datasets.forEach(dataset => {
      //    dataset.data.push(Math.random() * 30)
      //    if (dataset.data.length > 30) {
      //      dataset.data.shift()
      //    }
      //  })
      //  this.chart.update()
      // }, 2000)
    },
    updateChartData() {
      let isOverflow = false

      Object.values(this.traffic).forEach(traffic => {
        const datasetDownload = this.chart.data.datasets[0]
        const datasetUpload = this.chart.data.datasets[1]
        isOverflow = datasetDownload.data.length > 30 || datasetUpload.data.length > 30

        datasetDownload.data.push(traffic.download)
        datasetUpload.data.push(traffic.upload)

        if (isOverflow) {
          datasetDownload.data.shift()
          datasetUpload.data.shift()
        }
      })

      this.chart.update(isOverflow ? 'none' : null)
    },
  },
}
</script>

<style scoped>

</style>
