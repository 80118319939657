<template>
  <b-card
    no-body
    class="table-border-container"
  >

    <b-row
      class="mt-2 mb-2"
    >
      <b-col>
        <div class="site-hardware-actions site-hardware-actions_management wifi-setting">
          <div class="wifi-setting-label">
            <p class="label">
              Blocked WiFi devices
            </p>
          </div>
          <div
            class="double-button actions-wifi-settings"
          >
            <b-button
              variant="primary"
              @click="navigateToCreate"
            >
              Create
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-card-body>
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        :rows="items"
        compact-mode
        mode="remote"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click.prevent="navigateToEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="deleteRow(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'days'">
            {{ props.formattedRow.days.filter((el) => el.enable).map((day) => day.title).join(', ') || '-' }}
          </span>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'

import {
  BCard, BCardBody, BDropdown, BDropdownItem, BButton, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { required, max } from '@validations'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCardBody,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BSpinner,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      fields: [
        { field: 'id', label: 'Id' },
        { field: 'mac_address', label: 'MAC' },
        { field: 'time_start', label: 'Time Start' },
        { field: 'time_end', label: 'Time End' },
        { field: 'days', label: 'Days' },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      required,
      max,
    }
  },
  computed: {
    ...mapState('blockList', {
      items: 'items',
    }),
  },
  created() {
    this.fetchBlockList()
  },
  methods: {
    ...mapActions('blockList', [
      'getItems',
      'deleteItem',
    ]),
    navigateToCreate() {
      this.$router.push({ name: 'site-summary-add-blocked-device', params: { id: this.hardwareId } })
    },
    navigateToEdit(id) {
      this.$router.push({ name: 'site-summary-edit-blocked-device', params: { id: this.hardwareId, blockedWifiId: id } })
    },
    async fetchBlockList() {
      try {
        await this.getItems(this.hardwareId)
      } catch (error) {
        const { status } = error.response

        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    async deleteRow(id) {
      try {
        await this.deleteItem({ id, hardwareId: this.hardwareId })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
