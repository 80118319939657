<template>
  <b-modal
    id="qrCodeModal"
    ref="qrCodeModal"
    title="Wi-Fi QR Code"
    size="lg"
    hide-footer
    centered
    hide-header-close
    @hide="closeModal"
  >
    <div class="p-1">
      <div class="qr-code-modal-body">
        <b-tabs
          v-if="!sameAs2g"
          v-model="activeTab"
          card
          pills
          class="qr-code-tabs"
        >
          <b-tab
            title="2G"
            active
            @click="changeTab('2g')"
          />
          <b-tab
            title="5G"
            @click="changeTab('5g')"
          />
        </b-tabs>

        <div>
          <b-row
            v-if="imageBase"
            class="qr-code-image-container"
          >
            <img
              :src="`data:image/svg+xml;base64,${imageBase}`"
              alt="QR Code"
              class="qr-code"
            >
          </b-row>
          <b-row v-else>
            <b-spinner
              label="Loading..."
              variant="primary"
            />
          </b-row>
        </div>
        <div class="legal-modal-footer mt-3">
          <b-button
            variant="primary"
            @click="handleDownload"
          >
            Download
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {
  BRow, BModal, BButton, BSpinner, BTabs, BTab,
} from 'bootstrap-vue'
import axios from '../../../axios-resolver'

export default {
  components: {
    BRow,
    BSpinner,
    BModal,
    BTabs,
    BTab,
    BButton,
  },
  props: {
    siteId: {
      type: String || Number,
      default: '',
    },
    wifiId: {
      type: Number,
      default: null,
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
    sameAs2g: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageBase: '',
      activeTab: '2g',
    }
  },
  watch: {
    wifiId(newWifiId) {
      if (newWifiId) {
        this.getQrCode()
      } else {
        this.imageBase = ''
        this.closeModal()
      }
    },
  },
  methods: {
    async getQrCode() {
      try {
        const { data: { data } } = await axios.get(`/site/${this.siteId}/wifi/qr/${this.wifiId}/${this.activeTab}`)
        this.imageBase = data.qr_code

        this.$refs.qrCodeModal.show()
      } catch (error) {
        console.warn(error)
      }
    },
    changeTab(tab) {
      this.activeTab = tab

      this.imageBase = ''
      this.getQrCode()
    },
    closeModal() {
      this.handleClose()

      this.$refs.legalExpiredModal.hide()
    },
    handleDownload() {
      const link = document.createElement('a')
      link.href = `data:image/svg+xml;base64,${this.imageBase}`
      link.download = `wifi-${this.wifiId}-${this.activeTab}.svg`
      link.click()
    },
  },
}
</script>

<style lang="scss">
</style>
