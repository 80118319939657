<template>
  <b-card
    no-body
    class="no-padding"
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      title="Are you sure you want to permanently delete this record?"
      centered
      ok-title="Delete"
      @ok="deleteRow"
    >
      <div>
        Permanently deleted records cannot be recovered later.
      </div>
    </b-modal>
    <div class="section-block m-2">
      <div class="site-hardware-actions site-hardware-actions_management mb-2">

        <div class="section-block__title mb-0">
          <h2>Address List</h2>
        </div>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.address-list-settings-modal
            variant="primary"
          >
            <feather-icon
              icon="PlusIcon"
              width="16"
              height="16"
              class="mr-25"
            />
            Add Entry
          </b-button>
        </div>
      </div>
      <b-card-body>
        <vue-good-table
          ref="VueGoodTable"
          :is-loading="isLoading"
          :columns="fields"
          :rows="items"
          mode="remote"
          compact-mode
        >
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'enable_server'">
              <b-form-checkbox
                v-model="props.row.is_active"
                name="is_enable"
                switch
                inline
                aria-label="fff"
                @change="updateAddressStatus(props.row.id, props.row.is_active)"
              />
            </span>
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click.prevent="handleEditItem(props.row)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!props.row.is_default"
                    @click.prevent="deleteHandler(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </div>

    <AddEntryModal
      :list-name="listName"
      :handle-close-modal="handleCloseModal"
    />
  </b-card>

</template>

<script>

import {
  BCard, BCardBody, BDropdown, BDropdownItem, BButton, BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'
import Ripple from 'vue-ripple-directive'

import { VueGoodTable } from 'vue-good-table'
import axios from '../../axios-resolver'
import AddEntryModal from './AddEntryModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardBody,
    VueGoodTable,
    AddEntryModal,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      listName: [],

      recordToDelete: null,
      fields: [
        {
          field: 'address',
          label: 'IP Address',
          sortable: false,
        },
        {
          field: 'list',
          label: 'Address List Name',
          sortable: false,
        },
        {
          field: 'comment',
          sortable: false,
          label: 'Comment',
        },
        {
          field: 'enable_server',
          label: 'Enabled',
          sortable: false,
        },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      required,
      max,
    }
  },
  watch: {
    recordToDelete(val) {
      if (val) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
  },
  created() {
    this.getLeases()
    this.getOptions()
  },
  methods: {
    handleEditItem(item) {
      this.$router.push({
        name: 'site-summary-edit-firewall-list',
        params: {
          id: +this.$route.params.id,
          listId: item.id,
        },
      })
    },
    async getOptions() {
      try {
        const { data: { data } } = await axios.get(`/site/${this.$route.params.id}/firewall/address-lists/options`)
        this.listName = data
      } catch (error) {
        console.log(error)
      }
    },
    navigateToUpdateDHCP(dhcpId) {
      this.$router.push({ name: 'site-summary-update-dhcp', params: { id: +this.$route.params.id, dhcpId } })
    },
    async getLeases() {
      const { data } = await axios.get(`/site/${this.$route.params.id}/firewall/address-lists`)
      this.items = data.data.map(item => ({
        ...item,
        is_active: item.disabled === 'false',
      }))
      this.isLoading = false
    },
    async updateAddressStatus(dhcpId, isEnable) {
      const switchTo = isEnable ? 'enable' : 'disable'
      try {
        await axios.put(`/site/${this.$route.params.id}/firewall/address-lists/${dhcpId}/${switchTo}`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: isEnable ? 'DHCP Server Enabled' : 'DHCP Server Disabled',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const { fields } = error.response.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.$refs.wifiSettingsModal.setErrors(fields)
        }
      }
    },
    async deleteHandler(id) {
      this.recordToDelete = id
    },

    async handleCloseModal() {
      this.getLeases()
    },
    async deleteRow() {
      try {
        await axios.delete(`/site/${this.$route.params.id}/firewall/address-lists/${this.recordToDelete}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.getLeases()
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
