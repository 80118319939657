<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <b-card
    no-body
  >
    <validation-observer ref="licenceCreate">
      <b-form>
        <b-row>
          <b-col
            md="12"
            class="pl-4 pr-4"
          >
            <b-overlay
              :show="isLoading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="FileTextIcon"
                    width="24"
                    height="24"
                  />
                  <h2>WAN Network Settings</h2>
                </div>
                <b-form-group
                  label="Type"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    vid="type"
                    rules="required"
                  >
                    <v-select
                      v-model="typeActive"
                      :options="types"
                      placeholder="Select type"
                      label="type"
                      :clearable="false"
                      :searchable="false"
                      :dropdown-should-open="() => true"
                      class="no-icon"
                      @input="checkFields"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div
                  v-if="typeActive.includes('PPPoE')"
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Username"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="username"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="form.username"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Username"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Password"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|max:255"
                    >
                      <b-form-input
                        v-model="form.password"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Password"
                        autocomplete="off"
                        type="password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Interface"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="interface_ip"
                      rules="max:255"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Interface"
                        autocomplete="off"
                        :onchange="(value) => form.interface_ip = value"
                        :disabled="typeActive.includes('PPPoE')|| typeActive.includes('DHCP Client')"
                        :model="form.interface_ip"
                        inputs-id="interface_ip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Subnet Mask"
                    label-class="label-class"
                    class="neighbouring-form-group"
                    :class="{ 'invisible': typeActive.includes('PPPoE') }"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="subnet_mask"
                      rules="max:255"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Subnet Mask"
                        autocomplete="off"
                        :onchange="(value) => form.subnet_mask = value"
                        :disabled="!typeActive.includes('Static')"
                        :model="form.subnet_mask"
                        inputs-id="subnet_mask"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="!typeActive.includes('PPPoE')"
                    label="Gateways Address"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="gateway_ip"
                      rules="max:255"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Gateways Address"
                        autocomplete="off"
                        :onchange="(value) => form.gateway_ip = value"
                        :disabled="typeActive.includes('DHCP Client')"
                        :model="form.gateway_ip"
                        inputs-id="gateway_ip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="typeActive.includes('PPPoE')"
                    label="Network Address"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="network_ip"
                      rules="max:255"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Network Address"
                        autocomplete="off"
                        :onchange="(value) => form.network_ip = value"
                        :disabled="true"
                        :model="form.network_ip"
                        inputs-id="network_ip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="double-button">
                  <b-button-request
                    type="reset"
                    text="Reset"
                    :variant="outline-secondary"
                    :request="resetForm"
                  />
                  <b-button-request
                    text="Save"
                    :request="validationForm"
                    :disabled="typeActive.includes('PPPoE') && (!form.interface_ip || !form.network_ip)"
                  />
                </div>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>

import {
  BCard, BForm, BFormGroup, BFormInput, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  components: {
    BButtonRequest,
    BCard,
    BForm,
    BFormGroup,
    BRow,
    BOverlay,
    BCol,
    vSelect,
    BFormIp,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
    siteId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      form: {
        type: '',
        interface_ip: '',
        subnet_mask: '',
        gateway_ip: '',
        username: '',
        password: '',
        network_ip: '',
        dhcp: {
          interface_ip: '',
          subnet_mask: '',
          gateway_ip: '',
        },
        pppoe: {
          interface_ip: '',
          network_ip: '',
        },
        static: {
          interface_ip: '',
          subnet_mask: '',
          gateway_ip: '',
        },
      },
      typeActive: '',
      typesStatic: ['DHCP Client', 'Static', 'PPPoE'],
      types: ['DHCP Client', 'Static', 'PPPoE'],
      itemToEdit: null,
      required,
      max,
    }
  },
  async created() {
    this.getWANInfo()
  },
  methods: {
    closeModal() {
      this.itemToEdit = null
    },
    checkFields() {
      this.$refs.licenceCreate.reset()
      if (this.typeActive.includes('PPPoE')) {
        this.form = {
          ...this.formRaw.pppoe,
          ...this.formRaw,
        }
      }
      if (this.typeActive.includes('DHCP Client')) {
        this.form = this.formRaw.dhcp
      }
      if (this.typeActive.includes('Static')) {
        this.form = this.formRaw.static
      }
    },
    getTypeEnum(label) {
      const labelRemoved = label.replace(' ☑️', '')
      const types = {
        'DHCP Client': 'dhcp',
        Static: 'static',
        PPPoE: 'pppoe',
      }

      return types[labelRemoved]
    },
    formatTypeBack(type) {
      if (!type) return ''
      const types = {
        dhcp: 'DHCP Client ☑️',
        static: 'Static ☑️',
        pppoe: 'PPPoE ☑️',
      }

      this.types = this.typesStatic.map(item => {
        if (types[type] === `${item} ☑️`) {
          return `${item} ☑️`
        }
        return item
      })

      return types[type]
    },
    async getWANInfo() {
      axios.get(`/site/${this.siteId}/network/setting/wan`)
        .then(response => {
          this.isLoading = false

          const { type } = response.data.data

          if (!type) {
            this.formRaw = {
              dhcp: {
                gateway_ip: '',
                interface_ip: '',
                subnet_mask: '',
              },
              pppoe: {
                interface_ip: '',
                network_ip: '',
              },
              static: {
                interface_ip: '',
                subnet_mask: '',
                gateway_ip: '',
              },
            }
          } else {
            this.formRaw = {
              ...response.data.data.payload,
              dhcp: {
                gateway_ip: type === 'dhcp' ? response.data.data.payload.gateway_ip : response.data.data.default.dhcp.gateway_ip,
                interface_ip: type === 'dhcp' ? response.data.data.payload.interface_ip : response.data.data.default.dhcp.interface_ip,
                subnet_mask: type === 'dhcp' ? response.data.data.payload.subnet_mask : response.data.data.default.dhcp.subnet_mask,
              },
              pppoe: {
                interface_ip: type === 'pppoe' ? response.data.data.payload.interface_ip : response.data.data.default.pppoe.interface_ip,
                network_ip: type === 'pppoe' ? response.data.data.payload.network_ip : response.data.data.default.pppoe.network_ip,
              },
              static: type === 'static' ? response.data.data.payload : {
                interface_ip: '',
                subnet_mask: '',
                gateway_ip: '',
              },
            }
          }
          this.typeActive = this.formatTypeBack(type, response.data.data.is_active)
          this.checkFields()

          // if ppoe and interface and network address is empty set error message in validation
          if (response.data.data.type === 'pppoe' && !response.data.data.payload.interface_ip && !response.data.data.payload.network_ip) {
            this.$refs.licenceCreate.setErrors({
              interface_ip: 'Unable to retrieve interface information from the hardware, please update the firmware or contact support for assistance.',
            })
          }
        })
        .catch(({ response }) => {
          this.isLoading = false
          console.warn(response)
        })
    },
    async validationForm() {
      const isValid = await this.$refs.licenceCreate.validate()
      if (isValid) {
        try {
          const payload = {
            ...this.form,
            type: this.getTypeEnum(this.typeActive),
          }
          const response = await axios.put(`site/${this.siteId}/network/setting/wan`, payload)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.form = {
            ...this.form,
            dhcp: {
              ...response.data.data.type === 'dhcp' ? response.data.data.payload.interface_ip : '',
            },
          }
          this.getWANInfo()
        } catch (error) {
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Validation error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
            this.$refs.licenceCreate.setErrors(fields)
          }
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation Error',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
