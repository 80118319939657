<template>
  <div class="position-relative">
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:20%; left:48%;"
    />
    <b-card
      v-else
      no-body
      class="p-0"
    >
      <b-tabs
        card
        pills
        @input="onInput"
      >
        <b-tab
          v-for="(title, key) in interfaces"
          :key="key"
          :title="title"
          active
        >
          <Chart
            :id="key"
            :traffic="trafficFormatted.filter(item => item.interface === key)"
          />
          <div
            v-for="(titleGraph, keyGraph) in items.graphs"
            :key="keyGraph"
          >
            <div
              v-if="keyGraph === key"
              class="d-flex flex-wrap"
            >
              <div
                v-for="(image, imageTitle) in titleGraph"
                :key="image"
                class="mr-1 mb-1"
              >
                <p>{{ imageTitle }}</p>
                <img
                  :src="image"
                  alt=""
                >
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, VBToggle, BSpinner, BTabs, BTab,
} from 'bootstrap-vue'
import Chart from './Chart.vue'

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BCard, BSpinner, BTabs, BTab, Chart,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      wifiItems: [],
      loading: true,
      visitedTabs: [],
      isActive: false,
      trafficFormatted: [],
    }
  },
  sockets: {
    connect() {
      console.log('Socket to notification channel connected')
    },
    // interfaceStatsEvent(data) {
    //   console.log(`stats: ${data}`)
    // },
    interfaceStatsEvent(val) {
      console.log(val)
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    },
  },
  watch: {
    traffic() {
      // go for each key and convert bits into kilobits
      this.trafficFormatted = this.traffic.map(item => {
        const newItem = { ...item, download: (item.download / 1024).toFixed(2), upload: (item.upload / 1024).toFixed(2) }
        return newItem
      })
    },
  },
  computed: {
    ...mapState('statistics', {
      items: 'items',
      interfaces: 'interfaces',
      traffic: 'traffic',
    }),
  },
  created() {
    this.$socket.client.open()
    this.fetchInterface()
  },
  mounted() {
  //  this.$socket.$subscribe('interfaceStatsEvent', payload => {
  //    console.log(payload)
  //  })
  },
  beforeDestroy() {
    this.isActive = false
  },
  methods: {
    ...mapActions('statistics', [
      'getItems',
      'getStatisticItems',
    ]),
    onInput(value) {
      if (!this.visitedTabs.includes(value)) {
        this.visitedTabs.push(value)
      }
    },
    async fetchInterface() {
      try {
        this.isActive = true

        setTimeout(async () => {
          const loadGraphsStatistics = async () => {
            await this.getItems(this.hardwareId)
          }

          await loadGraphsStatistics()
        }, 100)

        const reloadTraffic = async () => {
          if (this.isActive) {
            setTimeout(async () => {
              await this.reloadStatistic()
              await reloadTraffic()
            }, 2_000)
          }
        }
        await reloadTraffic()

        await this.reloadStatistic()

        this.$socket.client.emit('interfaceStatsEvent', Object.keys(this.items.interfaces))
      } catch (error) {
        const { status } = error.response

        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.loading = false
      }
    },
    async reloadStatistic() {
      await this.getStatisticItems(this.hardwareId).catch(err => {
        console.log('error in getStaticItems:', err)
        this.isActive = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.card{
    &-header{
        padding: 0 !important;
    }
}
</style>
