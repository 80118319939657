var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-modal',{ref:"modal",attrs:{"id":"access-point-modal","title":"Edit Access Point","hide-footer":"","size":"lg"},on:{"hide":_vm.closeModal,"show":_vm.openModal}},[_c('b-form',[_c('validation-observer',{ref:"accessPointModal"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Interface Name:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Interface Name:","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Interface Name:","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Comment:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Comment:","vid":"comment","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Comment:","autocomplete":"off"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"SSID:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"SSID:","vid":"ssid","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"SSID:","autocomplete":"off"},model:{value:(_vm.form.ssid),callback:function ($$v) {_vm.$set(_vm.form, "ssid", $$v)},expression:"form.ssid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Channel Selection:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Channel Selection:","vid":"channel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.channelList,"reduce":function (channel) { return channel.id; },"searchable":false,"label":"name","transition":""},model:{value:(_vm.form.channel),callback:function ($$v) {_vm.$set(_vm.form, "channel", $$v)},expression:"form.channel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Disable:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Disable:","vid":"disabled"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"disabled","switch":"","inline":""},model:{value:(_vm.form.disabled),callback:function ($$v) {_vm.$set(_vm.form, "disabled", $$v)},expression:"form.disabled"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button-request',{attrs:{"disabled":_vm.disabledSaveButton,"text":"Submit","request":_vm.editItem,"class-container":"d-flex justify-content-end"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }