<template>
  <b-card
    no-body
  >
    <b-modal
      id="address-list-settings-modal"
      ref="wifiSettingsModalRef"
      title="Add new entry"
      hide-footer
      size="lg"
      @hide="closeModal"
    >
      <b-form>
        <validation-observer ref="wifiSettingsModal">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="IP Address:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="IP Address"
                  vid="address"
                  rules="max:255"
                >
                  <b-form-ip
                    :errors="errors"
                    placeholder="IP Address"
                    autocomplete="off"
                    :onchange="(value) => form.address = value"
                    :model="form.address"
                    inputs-id="address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Address List Name:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address List Name"
                  vid="list"
                  rules="required"
                >
                  <v-select
                    v-model="form.list"
                    :options="options"
                    :searchable="true"
                    label="list"
                    transition=""
                    @search="handleListChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Comment:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comment"
                  vid="comment"
                  rules="max:255"
                >
                  <b-form-input
                    v-model="form.comment"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Comment"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Enabled:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Enabled:"
                  vid="enabled"
                >
                  <b-form-checkbox
                    v-model="form.enabled"
                    name="is_hidden"
                    switch
                    inline
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button-request
                text="Add"
                :disabled="disabledSaveButton"
                :request="addItem"
                class-container="d-flex justify-content-end"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>

import {
  BCard, BRow, BCol, BModal, BFormInput, BFormGroup, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import vSelect from 'vue-select'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  components: {
    BButtonRequest,
    BRow,
    BCol,
    BCard,
    BFormIp,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    listName: {
      type: Array,
      default: () => [],
    },
    handleCloseModal: {
      type: Function,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      max,
      form: {
        address: '',
        list: '',
        comment: '',
        enabled: true,
      },
      disabledSaveButton: false,
    }
  },
  watch: {
    listName() {
      this.options = [...this.listName]
    },
  },
  created() {
    this.options = [...this.listName]
  },
  methods: {
    async addItem() {
      const valid = await this.$refs.wifiSettingsModal.validate()
      if (valid) {
        try {
          this.disabledSaveButton = true
          const payload = {
            ...this.form,
            disabled: !this.form.enabled,
          }
          await axios.post(`/site/${this.$route.params.id}/firewall/address-lists`, payload)
          this.$bvModal.hide('wifi-settings-modal')
          this.closeModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          if (error.response && error.response.status === 422) {
            const { fields } = error.response.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
            this.$refs.wifiSettingsModal.setErrors(fields)
          }
        } finally {
          this.disabledSaveButton = false
        }
      }
    },
    handleListChange(value) {
      if (!value) {
        return
      }
      this.form.list = value

      if (this.options.length === this.listName.length) {
        this.options.unshift(value)
      } else {
        this.options = this.options.map((item, index) => {
          if (index === 0) {
            return value
          }
          return item
        })
      }
    },
    closeModal() {
      this.form = {}

      this.handleCloseModal()
      this.$refs.wifiSettingsModalRef.hide()
    },
  },
}
</script>

<style lang="scss">
</style>
