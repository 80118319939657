var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-modal',{ref:"wifiSettingsModalRef",attrs:{"id":"address-list-settings-modal","title":"Add new entry","hide-footer":"","size":"lg"},on:{"hide":_vm.closeModal}},[_c('b-form',[_c('validation-observer',{ref:"wifiSettingsModal"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"IP Address:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IP Address","vid":"address","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"IP Address","autocomplete":"off","onchange":function (value) { return _vm.form.address = value; },"model":_vm.form.address,"inputs-id":"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address List Name:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address List Name","vid":"list","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.options,"searchable":true,"label":"list","transition":""},on:{"search":_vm.handleListChange},model:{value:(_vm.form.list),callback:function ($$v) {_vm.$set(_vm.form, "list", $$v)},expression:"form.list"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Comment:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Comment","vid":"comment","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Comment","autocomplete":"off"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Enabled:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Enabled:","vid":"enabled"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"is_hidden","switch":"","inline":""},model:{value:(_vm.form.enabled),callback:function ($$v) {_vm.$set(_vm.form, "enabled", $$v)},expression:"form.enabled"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button-request',{attrs:{"text":"Add","disabled":_vm.disabledSaveButton,"request":_vm.addItem,"class-container":"d-flex justify-content-end"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }