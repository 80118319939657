<template>
  <b-card
    no-body
  >
    <b-card-title class="m-2">
      Static IP Reservations
    </b-card-title>
    <b-modal
      id="modal-edit"
      ref="modal"
      title="Edit DHCP item"
      hide-footer
      size="md"
      @hidden="closeModal"
    >
      <validation-observer ref="dhcpReservationsUpdateModal">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="MAC Address:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="MAC Address:"
                  vid="mac_address"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="itemToEdit.mac_address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="MAC Address:"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Address:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address:"
                  vid="address"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="itemToEdit.address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address:"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                @click.prevent="editItem"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-card-body>
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        :rows="items"
        compact-mode
        mode="remote"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-edit
                  @click.prevent="itemToEdit = {...props.row}"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="deleteRow(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'

import {
  BCard, BCardTitle, BCardBody, BDropdown, BDropdownItem, BForm, BFormGroup, BFormInput, BModal, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCardBody,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      fields: [
        { field: 'id', label: 'Id' },
        { field: 'mac_address', label: 'MAC' },
        { field: 'address', label: 'Address' },
        { field: 'active_host_name', label: 'Active host name' },
        { field: 'last_seen', label: 'Last seen' },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      itemToEdit: null,
      required,
      max,
    }
  },
  computed: {
    ...mapState('dhcp', {
      items: 'reservations',
    }),
  },
  created() {
    this.fetchItems()
  },
  methods: {
    ...mapActions('dhcp', [
      'getReservations',
      'updateItem',
      'deleteItem',
    ]),
    async fetchItems() {
      try {
        await this.getReservations(this.hardwareId)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    async editItem() {
      const valid = await this.$refs.dhcpReservationsUpdateModal.validate()

      if (valid) {
        try {
          await this.updateItem({ id: this.hardwareId, payload: this.itemToEdit, isStatic: true })
          this.$refs.modal.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.dhcpReservationsUpdateModal.setErrors(fields)
          } else {
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })
          }
        }
      }
    },
    async deleteRow(id) {
      try {
        await this.deleteItem({ id, hardwareId: this.hardwareId, isStatic: true })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    closeModal() {
      this.itemToEdit = null
    },
  },
}
</script>

<style lang="scss">
</style>
