<template>
  <b-modal
    id="modal-add-hardware"
    ref="modal"
    title="Add hardware"
    hide-footer
    size="xl"
    @hidden="closeModal"
  >
    <b-card-body>
      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :columns="fields"
        :rows="items"
        mode="remote"
        compact-mode
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <b-button-request
              v-if="!successfullyUploadedIds.find(itemId => itemId === props.row.id)"
              class="d-flex align-items-center w-100 justify-content-center"
              :disabled="disabledSaveButton"
              text="Add Hardware"
              :request="() => addHardwareToSite(props.row.id)"
            />
            <div v-else>
              <b-button
                type="submit"
                class="d-flex align-items-center w-100 justify-content-center"
                variant="success"
                disabled
              >
                Hardware added
              </b-button>
            </div>
          </span>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-modal>
</template>

<script>
import {
  BCardBody, BSpinner, BModal, VBTooltip, BButton,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButtonRequest,
    BCardBody,
    BSpinner,
    BButton,
    BModal,
    VueGoodTable,
  },
  props: {
    update: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      updatingIdList: [],
      successfullyUploadedIds: [],
      fields: [
        {
          field: 'id',
          label: 'ID',
        },
        {
          field: 'model.name',
          label: 'Model',
        },
        {
          field: 'serial_number',
          label: 'Serial No',
        },
        {
          field: 'licence.name',
          label: 'Licence',
        },
        {
          field: 'warranty_expiry_at',
          label: 'Warranty Expiry Date',
        },
        {
          field: 'comment',
          label: 'Comment',
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'add-hardware-to-site-action-th-width',
          hidden: !this.$can('update', 'users'),
        },
      ],
    }
  },
  computed: {
    ...mapState('status', {
      items: 'availableToSiteItems',
    }),
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    ...mapActions('status', {
      fetchItems: 'getAvailableToSiteItems',
      setHardwaresToSite: 'setHardwaresToSite',
    }),
    async addHardwareToSite(id) {
      this.updatingIdList.push(id)
      try {
        await this.setHardwaresToSite({ siteId: this.$route.params.id, hardwareIds: [id] })
        this.successfullyUploadedIds.push(id)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hardware not found',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.updatingIdList = this.updatingIdList.filter(itemId => itemId !== id)
      }
    },
    async closeModal() {
      await this.update()
      this.$refs.modal.hide()
    },
    async getData() {
      await this.fetchItems()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .add-hardware-to-site-action-th-width {
    width: 190px!important;
  }
  .table-selected-row-info-panel{
    display: flex;
    align-items: center;
    padding: 10px;
    a{
      color: red
    }
  }
  .vgt-pull-right{
    float: unset !important;
    margin-left: auto;
  }
  .dark-layout{
    .vgt-select{
      background-color: #283046;
      border-color: #404656;
    }
    .table-selected-row-info-panel{
      background-color: #283046;
      border-color: #404656;
    }
  }
</style>
