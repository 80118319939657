<template>
  <b-card
    class="p-0 card-info border"
    style="border-top: 4px solid #F0554E !important; border-radius:4px"
  >
    <b-card-title class="m-2">
      LAN Details
    </b-card-title>
    <div
      class="p-2"
    >
      <div>
        <p><strong>Public IP:  </strong>{{ details.ip.address }}</p>
      </div>
      <div>
        <p><strong>IP Pool:   </strong>{{ details.pool.address_start }}-{{ details.pool.address_end }}</p>
      </div>
      <div v-if="details.dns">
        <p><strong>Router DNS:   </strong>
          <span>
            <span v-if="details.dns.servers">
              <span>{{ details.dns.servers.join(', ') }}</span>
            </span>
          </span></p>
      </div>
      <div v-else>
        <p><strong>Router DNS:   </strong><span style="padding: 5px 10px; background-color:green; color: white">Dynamic</span></p>
      </div>
      <div>
        <p><strong>DHCP DNS:  </strong>{{ details.dhcp_network.dns_server }}</p>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardTitle,
  },
  props: {
    details: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
</style>
