<template>
  <b-card
    no-body
  >
    <b-modal
      id="access-control-modal"
      ref="modal"
      title="Manage Access Control"
      hide-footer
      size="lg"
      @hide="closeModal"
      @show="openModal"
    >
      <b-form>
        <validation-observer ref="accessControlModal">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="MAC Address:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="MAC Address:"
                    vid="mac_address"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.mac_address"
                      :state="errors.length > 0 ? false:null"
                      placeholder="MAC Address:"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Comment:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Comment:"
                    vid="comment"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="form.comment"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Comment:"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Signal start:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Signal start:"
                    vid="signal_range_start"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.signal_range_start"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Signal start:"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Signal end:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Signal end:"
                    vid="signal_range_end"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.signal_range_end"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Signal end:"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Action:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Action:"
                    vid="action"
                    rules="required"
                  >
                    <v-select
                      v-model="form.action"
                      :options="actionList"
                      :reduce="action => action.name"
                      :searchable="false"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-row
                class="p-1"
              >
                <b-col
                  cols="6"
                  mb="6"
                  lg="4"
                >
                  <p class="mb-50">
                    Start Time:
                  </p>
                  <validation-provider
                    #default="{ errors }"
                    name="startTime"
                    vid="time_start"
                    rules="required"
                  >
                    <VueTimepicker
                      v-model="form.time_start"
                      format="HH:mm:ss"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="6"
                  mb="6"
                  lg="4"
                >
                  <p class="mb-50">
                    End Time:
                  </p>
                  <validation-provider
                    #default="{ errors }"
                    name="endTime"
                    vid="time_end"
                    rules="required"
                  >
                    <VueTimepicker
                      v-model="form.time_end"
                      format="HH:mm:ss"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex mt-1 flex-wrap "
                >
                  <div
                    v-for="(item, index) in daysSelection"
                    :key="index"
                    class="d-flex align-items-center justify-content-between"
                    style="width:70px;"
                  >
                    <p class="mb-0 mr-50">
                      {{ item.title }}
                    </p>
                    <b-form-checkbox
                      v-model="item.enable"
                      class="mr-0"
                      inline
                    />
                  </div>
                </b-col>
              </b-row>
              <b-col md="12">
                <b-form-group
                  label="Disable:"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Disable:"
                    vid="disabled"
                  >
                    <b-form-checkbox
                      v-model="form.disabled"
                      name="disabled"
                      switch
                      inline
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="text-right"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="disabledSaveButton"
                  @click.prevent="isEditForm ? editItem() : addItem()"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>

import { mapActions } from 'vuex'

import {
  BCard, BButton, BRow, BCol, BModal, BFormInput, BFormGroup, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { required, max } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    VueTimepicker,
    vSelect,
  },
  props: {
    siteId: {
      required: true,
      type: String,
    },
    isEditForm: {
      required: true,
      type: Boolean,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      max,
      form: {
        mac_address: '',
        time_start: '00:00:00',
        time_end: '00:00:00',
        comment: '',
        signal_range_start: '',
        signal_range_end: '',
        days: [],
        disabled: false,
      },
      disabledSaveButton: false,
      actionList: [
        {
          name: 'accept',
        },
        {
          name: 'reject',
        },
      ],
      daysSelection: [],
    }
  },
  created() {
    this.createDays()
  },
  methods: {
    ...mapActions('accessControl', [
      'updateItem',
      'createItem',
    ]),
    openModal() {
      if (this.selectedItem) {
        if (this.selectedItem.days) {
          this.daysSelection = [
            ...this.selectedItem.days,
          ]
        }
        this.form = {
          ...this.selectedItem,
        }
      }
    },
    async addItem() {
      const valid = await this.$refs.accessControlModal.validate()
      if (valid) {
        this.prepareForm()
        try {
          this.disabledSaveButton = true
          await this.createItem({ siteId: this.siteId, payload: this.form })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          if (error.response && !error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          if (error.response && error.response.status === 422) {
            const { fields } = error.response.data

            this.$refs.accessControlModal.setErrors(fields)
          } else {
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })
          }
        } finally {
          this.$bvModal.hide('access-control-modal')
          this.disabledSaveButton = false
          this.closeModal()
        }
      }
    },
    async editItem() {
      const valid = await this.$refs.accessControlModal.validate()
      if (valid) {
        this.prepareForm()
        try {
          this.disabledSaveButton = true
          await this.updateItem({ siteId: this.siteId, payload: this.form })
          this.$refs.modal.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.accessControlModal.setErrors(fields)
          } else {
            const { message } = error.response.data

            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'BellIcon',
                variant: 'danger',
                message,
              },
            })
          }
        } finally {
          this.$bvModal.hide('access-control-modal')
          this.disabledSaveButton = false
          this.closeModal()
        }
      }
    },
    prepareForm() {
      this.form.days = []
      this.daysSelection.forEach((item, index) => {
        if (item.enable) {
          this.form.days.push(index + 1)
        }
      })
    },
    closeModal() {
      this.form = {
        mac_address: '',
        time_start: '00:00:00',
        time_end: '00:00:00',
        comment: '',
        signal_range_start: '',
        signal_range_end: '',
        days: [],
        disabled: false,
      }
      this.$emit('close-modal')
    },
    createDays() {
      this.daysSelection = [
        {
          title: 'Mon',
          enable: true,
          value: 1,
        },
        {
          title: 'Tue',
          enable: true,
          value: 2,
        },
        {
          title: 'Wed',
          enable: true,
          value: 3,
        },
        {
          title: 'Thu',
          enable: true,
          value: 4,
        },
        {
          title: 'Fri',
          enable: true,
          value: 5,
        },
        {
          title: 'Sat',
          enable: true,
          value: 6,
        },
        {
          title: 'Sun',
          enable: true,
          value: 7,
        },
      ]
    },
  },
}
</script>

<style lang="scss">
</style>
